@import '../colorScheme'
@import '../mediaQueries'
@import '../constants'

// Constants
$rowIncrement = 1.6em
$size1 = 9
$size2 = 12
$size3 = 16
$size4 = 20
$size5 = 23
$size6 = 25

.masonryContainer
  margin 30px auto
  position relative
  display flex
  flex-direction column
  width 100%
  @media(max-width $tablet)
    margin 10px auto

.masonryGridContainer
  position relative
  width 100%
  max-width 1180px
  display grid
  grid-template-columns repeat(4, minmax(0, 1fr))
  grid-auto-rows $rowIncrement
  justify-content center
  margin auto
  @media(max-width 1200px)
    max-width calc(100% - 1.5em)
  @media(max-width $laptop)
    grid-template-columns repeat(3, minmax(0, 1fr))
  @media(max-width $tablet)
    grid-template-columns repeat(2, minmax(0, 1fr))

.masonryCardContainer
  position relative
  display flex
  flex-direction column
  margin 8px
  margin-bottom 11px
  border-radius $largeBorderRadius
  @media(max-width $tablet)
    margin 5px
    margin-bottom 7px

.grid-card
  position relative
  display flex
  width 100%
  height 100%
  border-radius $largeBorderRadius
  box-shadow 0px 3px 6px 0.2px #ccc
  overflow hidden
  background $lightGrey
  cursor pointer
  text-decoration none
  opacity 0.99 /* Hacky fix for overflow bug on round corners using grid */
  &:hover
    opacity 0.8

.objPic
  position absolute
  width 40%
  height auto
  top 50%
  left 50%
  mix-blend-mode multiply
  &:nth-child(1)
    transform translate(-150%, -75%) rotate(-30deg)
  &:nth-child(2)
    transform translate(50%, -25%) rotate(-30deg)
  .objImage
    width 100%
    height 100%
    max-width 100%
    @supports(-ms-ime-align:auto) /* Edge 16+ blend fix */
      display none
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) /* IE 10-11 blend fix */
      display none
  &.wideDiagonal
    width 100%
    &:nth-child(1)
      transform translate(-100%, -155%) rotate(-135deg)
    &:nth-child(2)
      transform translate(0%, 25%) rotate(45deg)
  &.thinMisaligned
    width 45%
    &:nth-child(1)
      transform translate(-125%, -30%) rotate(-10deg)
    &:nth-child(2)
      transform translate(20%, -25%) rotate(5deg)
  &.squaredMisaligned
    width 50%
    &:nth-child(1)
      transform translate(-120%, -35%) rotate(-10deg)
    &:nth-child(2)
      transform translate(10%, -32%) rotate(8deg)
  &.wideMisaligned
    width 75%
    &:nth-child(1)
      transform translate(-90%, -15%) rotate(82deg)
    &:nth-child(2)
      transform translate(-10%, -10%) rotate(-85deg)
  &.superWideMisaligned
    width 100%
    &:nth-child(1)
      transform translate(-75%, 35%) rotate(80deg)
    &:nth-child(2)
      transform translate(-27%, 40%) rotate(-80deg)

.singleObjImage
  object-fit cover
  height 100%
  width 100%
  mix-blend-mode multiply
  @supports(-ms-ime-align:auto) /* Edge 16+ blend fix*/
    z-index -1
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) /* IE 10-11 blend fix*/
    z-index -1
  &.centered
    object-fit contain
    padding 5px
  &.thin
    object-fit contain
    position absolute
    width 90%
    height auto
    top 10%
    left 20%
    max-width 100%
    @supports(-ms-ime-align:auto) /* Edge 16+ blend fix */
      display none
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) /* IE 10-11 blend fix */
      display none
  &.wide
    object-fit contain
    position absolute
    width 150%
    height auto
    top 15%
    left -40%
    @supports(-ms-ime-align:auto) /* Edge 16+ blend fix */
      display none
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) /* IE 10-11 blend fix */
      display none
  &.dupTopMisaligned
    position absolute
    width 60%
    height auto
    top 0
    filter grayscale(100%)
    mix-blend-mode multiply
    @supports(-ms-ime-align:auto) /* Edge 16+ blend fix*/
      z-index -1
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) /* IE 10-11 blend fix*/
      z-index -1
    &:nth-child(1)
      transform rotate(-30deg) translate(0, -40%)
    &:nth-child(2)
      transform rotate(-30deg) translate(15%, 70%)

.standardImage
  object-fit cover
  height 100%
  width 100%
  mix-blend-mode multiply
  @supports(-ms-ime-align:auto) /* Edge 16+ blend fix*/
    z-index -1
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) /* IE 10-11 blend fix*/
    z-index -1

.videoPlayMask
  position absolute
  width 100%
  height 100%
  display flex
  flex-direction row
  align-items center
  justify-content center
  font-size 40px
  color white
  background rgba(33, 31, 33, 0.6)
  opacity 0.8

.cardText
  position absolute
  bottom 0
  margin 10px
  font-size 1.7em
  line-height 1em
  font-weight bolder
  color white
  &:first-letter
    text-transform uppercase
  @media(max-width $tablet)
    font-size 1.2em

.misalignedComparisonCardText
  align-self center
  color white
  left 35%
  margin 10px auto
  font-size 1.7em
  line-height 1em
  font-weight bolder
  &:first-letter
    text-transform uppercase
  @media(max-width $tablet)
    font-size 1.2em

.cardFooter
.cardFooterContainer
  color $darkGray
  margin 5px
  font-size .9em
  line-height 1.3em

.highlighted
  color black
  font-weight bold

.size-1
  grid-row-end span $size1
  @media(max-width $tablet)
    grid-row-end span $size1
.size-2
  grid-row-end span $size2
  @media(max-width $tablet)
    $size1 = 9
    grid-row-end span $size1
.size-3
  grid-row-end span $size3
  @media(max-width $tablet)
    $size1 = 12
    grid-row-end span $size1
.size-4
  grid-row-end span $size4
  @media(max-width $tablet)
    $size1 = 15
    grid-row-end span $size1
.size-5
  grid-row-end span $size5
  @media(max-width $tablet)
    $size1 = 17
    grid-row-end span $size1
.size-6
  grid-row-end span $size6
  @media(max-width $tablet)
    $size1 = 20
    grid-row-end span $size1

.tint-blue
  background $bannerBlue
  .cardText
    color white
.tint-red
  background $bannerCrimson
  .cardText
    color white
.tint-grey
  background $backGrey
  filter grayscale(50%)
  .cardText
    color white
.tint-lightGrey
  background $lightGray
  filter grayscale(50%)
.tint-darkGrey
  background $gradientDarkGrey
  filter grayscale(50%)

.fadeToWhiteMask
  position absolute
  border-radius 0 0 7px 7px
  bottom 17px
  background-image $fadingWhiteGradient
  height 310px
  width 100%
  pointer-events none
  @media(max-width $tablet)
    bottom 0

.landingSlogan
  position relative
  display flex
  flex-direction column
  align-items center
  justify-content center
  max-width 62rem
  margin auto
  margin-bottom 3em
  @media (max-width $tablet)
    margin-bottom 2.5rem
    max-width 21rem

  .landingSloganTitle
    text-align center
    font-size 2rem
    line-height 2rem
    font-weight 700
    letter-spacing -0.025em
    color #313131
    white-space pre-wrap
    margin-bottom 0
    @media (min-width $tablet)
      font-size 3.75rem
      line-height 1

  .landingSloganText
    text-align center
    width 90%
    margin-top 1.5rem
    margin-bottom 0
    font-size 1.1rem
    font-weight normal
    font-style normal
    font-stretch normal
    line-height 1.85rem
    letter-spacing normal
    color #313131
    @media (max-width $tablet)
      margin-left 1rem
      margin-right 1rem
      font-size 1rem

  .landingSloganButtons
    margin-top 2.5rem
    display flex
    flex-direction row
    align-items center
    justify-content center
    text-align center
    column-gap 1.5rem
    @media (max-width $tablet)
      margin-top 1rem
      column-gap 0
    .landingSloganCatsLink
      text-decoration none
      border-radius 50px
      background-color rgb(60 89 252)
      padding 0.8rem 2rem
      font-size 0.875rem
      line-height 1.25rem
      font-weight 600
      color white
      &:hover
        background-color rgb(99 102 241)
      &:focus-visible
        outline-style solid
        outline-width 2px
        outline-offset 2px
        outline-color #4f46e5
      @media (max-width $tablet)
        padding 0.6rem 1.5rem

    .landingSloganHowLink
      text-align center
      text-decoration none
      font-size 0.875rem
      line-height 1.25rem
      font-weight 600
      @media (max-width $tablet)
        padding 0.8rem 1rem

