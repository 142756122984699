@import '../colorScheme'
@import '../mediaQueries'

.hero
  padding-top 60px
  height 96vh
  max-height 820px
  width 100%
  background radial-gradient(ellipse 120% 210% at 95% 0%, #531889 10%, #000000 70%)
  display flex
  flex-direction row
  color white
  align-items flex-start
  position relative
  @media(max-width $tablet)
    background radial-gradient(ellipse 750px 1000px at 95% 0%, #531889 0%, #000000 70%)
    height 96vh
    max-height 700px
    padding-top 30px

  a
    text-decoration none
    cursor pointer
    color $normalGray2
    &:hover
      text-decoration underline

  .curve
    position absolute
    bottom -1px
    left 0
    width 100%
    height 90px
    z-index 1
    @media(max-width $tablet)
      height 40px
    &.transparent
      opacity .2

  .content
    display flex
    flex-direction column
    width 100%
    align-items center
    margin-top 50px
    .titleContainer
      display flex
      flex-direction row
      width 90%
      justify-content center
      align-items center
      flex-wrap wrap
      margin-top 30px
      margin-bottom 20px
    .title, .titleLastWord
      display flex
      flex-direction row
      flex-wrap wrap
      justify-content center
      align-items center
      max-width 90%
      position relative
      text-transform lowercase
      font-size 6rem
      letter-spacing -4px
      z-index 2
      margin 0
      @media(max-width $tablet)
        font-size 2.7rem
        letter-spacing -2px
        &.smallerFont
          font-size 2.2rem
          letter-spacing -1.5px
    .title
      margin-right 10px
      @media(max-width $tablet)
        margin-right 5px
    .titleLastWord
      margin-left 21px
      &.underscored
        &:after
          content ''
          position absolute
          right -10px
          bottom 15px
          background transparent linear-gradient(92deg, #3C59FC 0%, #7600E0 100%) 0% 0% no-repeat padding-box
          width calc(100% + 12px)
          height 10px
          z-index -1
      @media(max-width $tablet)
        margin-left 10px
        &.underscored
          &:after
            right -3px
            bottom 7px
            height 5px
            width calc(100% + 4px)
    .subTitle
      text-align center
      font-size 1.5em
      color white
      padding 0 10%
      font-weight 400
      z-index 1
      margin-top 0
      margin-bottom 3em
      display block
      &:first-letter
        text-transform uppercase
      @media(max-width $tablet)
        font-size 1em
      a
        color white
        text-decoration none
        &:hover
          text-decoration underline

    .inputContainer
      width 90%
      max-width 600px
      margin 0 auto 2em
      position relative
      z-index 2

  .button
    position absolute
    height 40px
    width 40px
    left calc(50% - 22px)
    bottom 90px
    cursor pointer
    display flex
    flex-direction: row
    align-items center
    justify-content center
    z-index 1
    @media(max-width $tablet)
      bottom 50px
  .flat
    bottom -30px
    @media(max-width $tablet)
      bottom 10px

  .angle
    animation-name wabble
    animation-duration 2s
    animation-iteration-count infinite
    svg
      fill $coolBlue

  .darkFadeMask
    position absolute
    width 100%
    height 250px
    bottom 0
    background linear-gradient(to bottom, transparent, black 200%)
    @media(max-width $tablet)
      height 200px

@keyframes wabble
  0%
    transform translateY(-1px)
  50%
    transform translateY(1px)
  100%
    transform translateY(-1px)
